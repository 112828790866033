import { createContext } from "react";
const initialstate = {
  que1: "",
  que2: "",
  que3: "",
  que4: "",
};
const NavigationContext = createContext(initialstate);
NavigationContext.displayName = "NavigationContext";

export default NavigationContext;

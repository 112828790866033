import React, { useContext } from "react";
import { QuestionSection, RadioRange } from "../../components/utility/quote";
import { navigate } from "gatsby-link";

import NavigationContext from "../../contexts/navigationContext";
import RadioCircle from "../../svgs/radioCircle";

const Next = "/navigation/Questions/2";
const Points = [
  {
    question: "How many vehicles require tracking?",
    progress: "20%",
    options: [
      { answer: "1-4", to: Next, icon: <RadioCircle /> },
      { answer: "5-9", to: Next, icon: <RadioCircle /> },
      { answer: "10-24", to: Next, icon: <RadioCircle /> },
      { answer: "25-49", to: Next, icon: <RadioCircle /> },
      { answer: "50+", to: Next, icon: <RadioCircle /> },
    ],
  },
];

export default function Question1({ title }) {
  const { que1, setQue1 } = useContext(NavigationContext);

  return Points.map((item) => {
    return (
      <QuestionSection
        title={title}
        question={item.question}
        progress={item.progress}
      >
        {item.options.map((items) => {
          return (
            <RadioRange
              key={items.answer}
              icon={items.icon}
              value={items.answer}
              title={items.answer}
              isSelected={que1 === items.answer}
              onChange={(e) => {
                setQue1(e.target.value);
                navigate(items.to);
              }}
            />
          );
        })}
      </QuestionSection>
    );
  });
}
